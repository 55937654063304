<template>
  <div class="page-login-desktop">

    <Table
      label="لیست مشترکین"
      icon="mdi-format-list-bulleted"
      :table="table"
    >

      <template slot="header">
        <v-btn
          @click="filters.modal = true"
          color="primary"
          large
          elevation="0"
        >
          <v-icon class="ml-2">
            mdi-magnify
          </v-icon>
          جستجو
        </v-btn>
      </template>

      <tr
        v-for="(item, index) in table.items"
        :key="index"
      >
        <td class="text-center">{{ index + 1 }}</td>
        <td class="text-center">
          <v-btn
            text
            :to="`/panel/users/edit/${item.user.id}`"
          >
            <span v-html="item.user.name"></span>
          </v-btn>
        </td>

        <td class="text-center">
          <span v-html="item.agency"></span>
        </td>

        <td class="text-center">
          <v-chip
            color="primary"
            class="ml-2 mb-1 mt-1"
            small
            v-for="(location, index2) in item.locations"
            :key="index2"
          >
            {{ location }}
          </v-chip>
        </td>

        <td class="text-center">
          <b class="text-body-2">
            {{ item.remandedDays }}
          </b>
        </td>
        <td class="text-center">
          {{ item.created_at }}
        </td>
        <td class="text-center">{{ item.subscriptionName }}</td>
        <td class="text-center">
          <v-chip
            color="success"
            small
            v-if="item.status"
          >
            فعال
          </v-chip>
          <v-chip
            color="error"
            small
            v-else
          >
            غیر فعال
          </v-chip>
        </td>
        <td
          class="text-center"
          style="width:200px;padding:0"
        >
          <EditButton @click="item.actions.edit" />
          <MessageButton @click="item.actions.message" />
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="item.actions.history"
                v-bind="attrs"
                v-on="on"
                icon
              >
                <v-icon>
                  mdi-format-list-bulleted
                </v-icon>
              </v-btn>
            </template>
            <span>
              تاریخچه خرید
            </span>
          </v-tooltip>

        </td>
      </tr>
    </Table>

    <Pagination
      v-model="filters.skip"
      :key="filters.skip"
      :totalItems="table.totalCount"
      :perPage="filters.take"
      @change="(page) => { fetchItems({ skip: page }) }"
    />

    <NewMessage
      @onSubmit="() => {
      newMessage.modal = false;
    }"
      :messageData="newMessage"
    />

    <v-dialog
      v-model="filters.modal"
      width="500"
    >
      <v-card>
        <v-card-title>
          <div class="font-wieght-bold text-body-1">
            فیلتر های جستجو
          </div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col :cols="6">
              <v-text-field
                hide-details
                outlined
                label="نام"
                v-model="filters.Nameandfamily"
              />
            </v-col>
            <v-col :cols="6">
              <v-text-field
                hide-details
                outlined
                label="نام آژانس"
                v-model="filters.Agencyname"
              />
            </v-col>
            <v-col :cols="6">
              <v-text-field
                hide-details
                outlined
                label="روز های باقی مانده"
                v-model="filters.remainingday"
                type="number"
              />
            </v-col>
            <v-col :cols="6">
              <v-select
                hide-details
                outlined
                label="وضعیت"
                v-model="filters.Active"
                item-text="name"
                item-value="id"
                :items="status"
              />
            </v-col>
            <v-col :cols="6">
              <v-text-field
                hide-details
                outlined
                label="شماره موبایل"
                v-model="filters.mobile"
              />
            </v-col>
            <v-col :cols="6">
              <v-combobox
                hide-details
                outlined
                label="منطقه"
                v-model="filters.location"
                :items="locations"
                item-value="id"
                item-text="name"
              />
            </v-col>
            <v-col :cols="6">
              <v-combobox
                hide-details
                outlined
                label="مرتب سازی براساس"
                v-model="filters.sort"
                :items="sortKeys"
                item-value="id"
                item-text="name"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="d-flex align-center justify-space-between">
          <v-btn
            color="warning"
            large
            @click="resetFilters"
          >
            ریست
          </v-btn>
          <v-btn
            color="primary"
            large
            @click="fetchItems({take: 10,skip: 0,})"
          >
            جستجو
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import Table from "@/components/Table";
import EditButton from "@/components/EditButton";
import MessageButton from "@/components/MessageButton";
import NewMessage from "@/components/NewMessage";
import Pagination from "@/components/Pagination";
import {
  updatePrice,
  fetchItems,
} from "@Newfiling/module-subscription/src/api";
import { removeCommas } from "@persian-tools/persian-tools";
import {
  AppConfig,
  getDataFromUrl,
  removeAppConfig,
  updateUrlQueries,
} from "@Newfiling/Services";
import Vue from "vue";

export default {
  name: "DesktopLogin",

  components: {
    Table,
    Pagination,
    NewMessage,
    datePicker: VuePersianDatetimePicker,
    EditButton,
    MessageButton,
  },

  data() {
    return {
      table: {
        headers: [
          "#",
          "کاربر",
          "نام آژانس",
          "مناطق",
          "مدت اعتبار",
          "تاریخ اخرین تغییر",
          "اشتراک",
          "وضعیت",
          "عملیات",
        ],
        items: [],
        totalCount: 0,
        loading: false,
      },

      priceItem: {
        loading: false,
        modal: false,
        id: null,
        subscriptionname: null,
        price: null,
        namesofneighborhoods: null,
        duration: null,
        active: 1,
        description: null,
      },

      status: [
        {
          id: null,
          name: "همه",
        },
        {
          id: 1,
          name: "فعال",
        },
        {
          id: 0,
          name: "غیر فعال",
        },
      ],

      locations: [],

      filters: {
        modal: false,
        Nameandfamily: "",
        Agencyname: "",
        Active: null,
        remainingday: null,
        codeajans: null,
        mobile: "",
        location: "",
        take: 10,
        skip: 0,
        sort: "",
      },

      sortKeys: [
        {
          id: 1,
          name: "جدید ترین ها",
        },
        {
          id: 2,
          name: "قدیمی ترین ها",
        },
        {
          id: 3,
          name: "بیشترین روز اشتراک",
        },
        {
          id: 4,
          name: "کمترین روز اشتراک",
        },
      ],

      newMessage: {
        modal: false,
        phoneNumber: null,
      },
    };
  },

  beforeMount() {
    this.$store.commit("Breadcrumb/setBreadcrumb", [
      {
        text: "پنل مدیریت نیوفایل",
        disabled: false,
        href: "/panel/dashboard",
      },
      {
        text: "لیست  مشترکین",
        disabled: true,
        href: "/panel/subscriptions/list",
      },
    ]);
  },

  mounted() {
    this.$nextTick(async () => {
      const appConfigs = (await AppConfig) || {};
      this.locations = (appConfigs?.neighborhoods || []).map((item) => {
        return {
          name: item.NeighborhoodName,
          id: item.Id,
        };
      });
      this.filters = {
        ...this.filters,
        ...getDataFromUrl(this.$route),
      };
      await this.fetchItems();
    });
  },

  methods: {
    async fetchItems(filters = {}, reset = false) {
      this.filters = {
        ...this.filters,
        ...filters,
      };

      if (reset) {
        this.filters = {
          ...this.filters,
          modal: false,
          Nameandfamily: "",
          Agencyname: "",
          Active: 1,
          remainingday: null,
          codeajans: null,
          mobile: "",
          location: "",
          take: 10,
          skip: 0,
          sort: "",
        };
      }

      this.table.loading = true;
      this.filters.modal = false;

      // this.filters = {
      //   ...this.filters,
      //   location: this.filters.location?.id,
      // }
      updateUrlQueries(this.$route.path, this.filters);
      try {
        const res = (
          await fetchItems({
            ...this.filters,
            location: this.filters.location?.id,
            skip: this.filters.skip * this.filters.take,
            sort: this.filters.sort?.id || 0,
            // remainingday:this.filters.remainingday === null ? undefined : this.filters.remainingday
          })
        )?.data || {
          recordcount: 0,
          list: [],
        };
        const getRemandedDays = (remandedDays) => {
          return remandedDays > 0
            ? remandedDays + " روز "
            : Math.abs(remandedDays) + "روز قبل";
        };
        this.table.totalCount = res.recordcount;
        this.table.items = res.list.map((item) => {
          return {
            id: item.id,
            user: {
              name: item.managername + "<br/>" + item.managermobile,
              id: item.userid,
            },
            agency: item.agencyname,
            locations: item.strNamesofneighborhoods.split("-"),
            remandedDays: getRemandedDays(item.remainingday),
            created_at: item.solarmodife,
            subscriptionName: item.subscriptionname,
            status: item.remainingday > 0,
            name: item.Subscriptionname,
            actions: {
              edit: async () => {
                await this.$router.push(
                  "/panel/subscriptions/edit/" + item.codeajans
                );
              },
              message: () => {
                this.newMessage = {
                  modal: true,
                  phoneNumber: item.managermobile.substring(1),
                };
              },
              history: () => {
                this.$router.push(
                  `/panel/subscriptions/history/${item.managermobile}`
                );
              },
            },
          };
        });
      } catch (e) {
        console.log(e);
      }
      this.table.loading = false;
    },

    async update() {
      this.priceItem.loading = true;
      try {
        await updatePrice({
          ...this.priceItem,
          price: removeCommas(this.priceItem.price),
        });
        this.$toast.success("اشتراک با موفقیت ویرایش شد.");
        await removeAppConfig();
        await this.fetchItems();
        window.location.reload();
      } catch (e) {
        console.log(e);
      }
      this.priceItem.loading = false;
      this.priceItem.modal = false;
    },

    resetFilters() {
      this.filters = {
        ...this.filters,
        modal: true,
        Nameandfamily: "",
        Agencyname: "",
        Active: null,
        remainingday: null,
        codeajans: null,
        take: 10,
        skip: 0,
        mobile: "",
        location: "",
        sort: "",
      };
    },
  },

  watch: {
    "priceItem.price"(newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => (this.priceItem.price = result));
    },
  },
};
</script>

